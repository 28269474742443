import React from 'react';
import { useState, useRef, useEffect, useContext } from 'react';
import { InputText } from 'primereact/inputtext';
import { Password } from 'primereact/password';
import { Dialog } from 'primereact/dialog';
import { Button } from 'primereact/button';
import { Toast } from 'primereact/toast';
import { useNavigate } from 'react-router-dom';
//import { AppContext } from '../index';
import { AppStateAtom } from '../atoms';
import { useRecoilValue } from 'recoil';
import { ObixUtil } from '../service/ObixUtil';
import { AppLogoEntry } from '../index';
import axios from 'axios';

export const Login = () => {
    //const appContext = useContext(AppContext);
    const appState = useRecoilValue(AppStateAtom);
    const navigate = useNavigate();
    const [userId, setUserId] = useState("");
    const [password, setPassword] = useState("");
    const toast = useRef(null);

    const goDashboard = () => {
        navigate('/');
    };

    const login = (e) => {
        console.log("Called login");
        let invokeUrl = appState.mmurl + "/obix/about/login";
        let body = {
            o: 'obix',
            c: [
                { o: "str", name: "id", val: userId },
                { o: "str", name: "password", val: password }
            ]
        }

        axios.post(invokeUrl, body, { headers: { 'X-HTTP-METHOD-OVERRIDE': 'POST' } })
            .then((resp) => {
                console.log(JSON.stringify(resp.data));
                let errmsg = ObixUtil.getErrorMsg(resp.data);
                if (errmsg) {
                    toast.current.show({ severity: 'error', summary: 'Failed to log in', detail: errmsg, life: 10000 });
                    setPassword("");  // 암호틀리면 클리어하려고 했으나... 안 먹네????
                } else {
                    const otoken = ObixUtil.find(resp.data, "token");
                    // localStorage에 저장
                    window.localStorage["mm_token"] = otoken.val;
                    //console.log(`token=${otoken.val}`);
                    // 결과 보여주는 창 띄우기
                    toast.current.show({ severity: 'success', summary: 'Logged in successfully', detail: "" });
                    // shouldChangePassword 저장
                    const oscp = ObixUtil.find(resp.data, "scp");
                    //alert(oscp.val);
                    window.localStorage["scp"] = oscp?.val ? oscp.val.toString() : "false";
                    setTimeout(() =>  window.location.href='/', 500);  // 바로 reload하면 안되어서, timer 줌
                }
            }).catch((error) => {
                let errmsg = error.response?.status + "=>" + error.message;
                toast.current.show({ severity: 'error', summary: 'Failed to log in', detail: errmsg, life: 3000 });
                //setWriting(false);
            });
    }

    const handleKeyPressed = (e) => {
        //console.dir(e);
        if (e.key === "Enter") {
            console.log("Enter key pressed");
            login(e);
        }
    }

    const onClickForgotPassword = (e) => {
        e.preventDefault();
        if (!userId) {
            toast.current.show({ severity: 'warn', summary: 'Fill in your user ID first', detail: '', life: 3000 });
            return;
        }
        let invokeUrl = appState.mmurl + "/obix/about/sendResetPwd";
        let body = {
            o: 'obix',
            c: [
                { o: "str", name: "id", val: userId }
            ]
        }

        ObixUtil.invokeObix(invokeUrl, body,
            (resp) => {
                const msg = ObixUtil.find(resp.data, "result")?.display;
                toast.current.show({ severity: 'success', summary: msg, detail: "" });
            },
            (err) => {
                toast.current.show({ severity: 'error', summary: 'Failed to reset password', detail: err, life: 3000 });
            }
        );
    }

    const signUp = (e) => {
        navigate('/signup');
    }

    return (
        <div className="pages-body login-page flex flex-column">
            <div className="topbar p-3 flex justify-content-between flex-row align-items-center">
                <div className="topbar-left ml-3 flex">
                    <AppLogoEntry/>
                </div>
                <div className="topbar-right mr-3 flex">
                    <Button onClick={goDashboard} type="button" label="DASHBOARD" className="p-button-text p-button-plain topbar-button"></Button>
                </div>
            </div>

            <div className="align-self-center mt-auto mb-auto">
                <div className="pages-panel card flex flex-column">
                    <div className="pages-header px-3 py-1">
                        <h2>LOGIN</h2>
                    </div>

                    <h4>Welcome</h4>

                    <div className="pages-detail mb-6 px-6">Please use the form to sign-in Mastermind</div>

                    <div className="input-panel flex flex-column px-3">
                        <div className="p-inputgroup">
                            <span className="p-inputgroup-addon">
                                <i className="pi pi-user"></i>
                            </span>
                            <span className="p-float-label">
                                <InputText type="text" id="inputgroup1" value={userId} onChange={(e) => setUserId(e.target.value)}/>
                                <label htmlFor="inputgroup1">User ID</label>
                            </span>
                        </div>

                        <div className="p-inputgroup mt-3 mb-6">
                            <span className="p-inputgroup-addon">
                                <i className="pi pi-lock"></i>
                            </span>
                            <span className="p-float-label">
                                <InputText type="password" id="inputgroup2" value={password} onChange={(e) => setPassword(e.target.value)}
                                    onKeyPress={handleKeyPressed}/>
                                <label htmlFor="inputgroup2">Password</label>
                            </span>
                        </div>
                    </div>

                    <Button className="login-button mb-3 mx-4 px-3" label="LOGIN" onClick={login}></Button>
                    <Button className="p-button-help mb-3 mx-4 px-3" label="Sign Up" onClick={signUp}></Button>
                </div>
            </div>
            <Toast ref={toast}></Toast>
        </div>
    );

};

