import { useState, useEffect, useRef, useContext, useCallback, useMemo } from 'react';
import { BreadCrumb } from 'primereact/breadcrumb';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Menu } from 'primereact/menu';
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import { InputSwitch } from 'primereact/inputswitch';
import { ProgressBar } from 'primereact/progressbar';
import { InputText } from 'primereact/inputtext';
import { SelectButton } from 'primereact/selectbutton';
import { InputNumber } from 'primereact/inputnumber';
import { Dropdown } from 'primereact/dropdown';
import { Toast } from 'primereact/toast';
import { TabView,TabPanel } from 'primereact/tabview';
//import JsonFormatter from 'react-json-formatter'
import JSONPretty from 'react-json-pretty';
import XMLViewer from 'react-xml-viewer'

//import { AppContext } from '../index';
import { AppStateAtom, AuthStateAtom } from '../atoms';
import { useRecoilValue } from 'recoil';
//import { AuthContext } from '../index';
import { ObixUtil } from '../service/ObixUtil';
//import axios from 'axios';
import classnames from 'classnames';
import { useLocation } from 'react-router-dom';

export function ObixStudio(props) {
    //const appContext = useContext(AppContext);
    const [url, setUrl] = useState("/obix");
    const [prevUrl, setPrevUrl] = useState("/obix");  // #79 들어갔다가, 404 등의 에러가 나면, 이전 URL로 돌아가기 위해
    const [refs, setRefs] = useState([]);
    const [ts, setTs] = useState(0);  // Breadcrumb에서 자신을 클릭해도 reload하게 하기 위해, 단순 증가 상태 사용
    const location = useLocation();  // react-router-dom의 location을 사용

    useEffect(() => {
        console.dir(location);
        if (location.hash) {
            setPrevUrl(url);
            setUrl(location.hash.substring(1));
        } else {
            setPrevUrl(url);
            setUrl("/obix");
        }
    }, [location]);

    // Breadcrumb나 Datagrid에서 다른 URL을 선택하게 되면, 위로 알려주는 콜백
    const updateUrl = (u) => {
        //setUrl(u);
        if (url !== u) {
            window.location.href = "/studio#"+(u);
        } else {
            setTs((prev) => prev+1);
        }
    }
    // Datagrid에서 ref들을 읽게 되면, 위로 알려주는 콜백
    const updateRef = (r) => {
        setRefs(r);
    }

    const obixDataGrid = useMemo(() => {
        return <ObixDataGrid url={url} updateUrl={updateUrl} updateRef={updateRef} ts={ts} prevUrl={prevUrl}/>;
    }, [url, ts]);

    const obixJsonView = useMemo(() => {
        return <ObixJsonView url={url} ts={ts} updateRef={updateRef}/>;
    }, [url, ts]);

    const obixXmlView = useMemo(() => {
        return <ObixXmlView url={url} ts={ts} updateRef={updateRef}/>;
    }, [url, ts]);

    return (
        <div>
            <ObixBreadcrumb url={url} updateUrl={updateUrl} refs={refs}/>
            <TabView>
                <TabPanel header="Table">
                    {/*<ObixDataGrid url={url} updateUrl={updateUrl} updateRef={updateRef} ts={ts}/>*/}
                    {obixDataGrid}
                </TabPanel>
                <TabPanel header="JSON">
                    {/*<ObixJsonView url={url} ts={ts}/>*/}
                    {obixJsonView}
                </TabPanel>
                <TabPanel header="XML">
                    {/*<ObixXmlView url={url} ts={ts}/>*/}
                    {obixXmlView}
                </TabPanel>
            </TabView>
        </div>
    );
}

function ObixBreadcrumb(props) {
    const [items, setItems] = useState([]);
    const [refs, setRefs] = useState([]);
    const cm = useRef(null);   // reference to ContextMenu
    const toast = useRef(null);

    useEffect(() => {
        let urlArray = props.url.split('/');
        let its = [];
        // 첫번쩨 녀석은 /obix로 시작하기 때문에 공백
        // 두번째 녀석은 obix. obix는 Home 으로 들어감
        let data = '/obix';
        for (let i = 2; i < urlArray.length; i++) {
            data = data + "/" + urlArray[i];
            its.push({
                label: urlArray[i],
                data: data,
                command: (e) => props.updateUrl(e.item.data)
            });
        }

        // 마지막에 More를 더 붙인다.
        // 그냥 toggle(e)를 하면 이상한 에러가 남. 원래 toggle이 원하는 것은 원래 event임.
        // breadcrumb는 originalEvent를 감싼 다른 형태의 event를 보냄.
        if (props.refs.length > 0) {
            // moreTemplate을 적용하면 click event가 안먹네... 뭐냐...
            const moreTemplate = (item, options) => {
                return (
                    <span><i className="pi pi-check"></i>more</span>
                );
            }
            its.push({label: "(more)", template: null, moreTemplate, command: (e) => cm.current.toggle(e.originalEvent)});
            setItems(its);

            // refs로 모델을 만든다.
            let refModel = [];
            for (let r of props.refs) {
                refModel.push({label: r, command: (e) => props.updateUrl(props.url + "/" + e.item.label)});
            }
            setRefs(refModel);
        } else {
            setItems(its);
            setRefs([]);
        }
    }, [props])

    // command에서 실제 action을 넣고, data에는 진행할 url을 넣자.

    // 무슨일인지 모르겠지만, Home icon과 "obix"를 같이 두려고 했으나, 먹지를 않음. template도 먹지 않음.
    const home = {
        icon: 'pi pi-home',
        label: 'obix',
        command: (e) => props.updateUrl(e.item.data),
        data: '/obix',
    }

    const copyURL = (e) => {
        navigator.clipboard.writeText(props.url).then(() => {
            toast.current.show({ severity: 'success', summary: 'URL copied into clipboard', detail: props.url });
        });
    }

    return (
        <div className="mb-2">
            <Menu model={refs} popup ref={cm}></Menu>
            <Toast ref={toast}></Toast>
            <div className="grid">
                <BreadCrumb model={items} home={home} className="col mr-2"/>
                <Button label="Copy URL" className="col-fixed p-button-help" onClick={copyURL}/>
            </div>
        </div>
    );
}

function ObixDataGrid(props) {
    //const appContext = useContext(AppContext);
    const appState = useRecoilValue(AppStateAtom);
    //const authContext = useContext(AuthContext);
    const auth = useRecoilValue(AuthStateAtom);
    const toast = useRef(null);
    const [obix, setObix] = useState({});
    const [rangeMap, setRangeMap] = useState(new Map());  // enum을 위한 것
    const [paramMap, setParamMap] = useState(new Map());  // op의 in parameter
    const [rowList, setRowList] = useState([]);
    const [selected, setSelected] = useState(null);  // DataTable에서 선택된 row를 나타냄
    const [loading, setLoading] = useState(false);  // 읽기/쓰기 등 작업할 때 Spinner 보여주는 플래그
    const [writing, setWriting] = useState(false);  // 쓰기 Dialog를 보여줄건가? 말건가?
    const [writeValue, setWriteValue] = useState(null);  // 쓰기 Dialog의 value
    const [invoking, setInvoking] = useState(false);  // Invoke Dialog 보여줄건가?
    const [invokeValues, setInvokeValues] = useState(new Map());  // Invoke Dialog에 보여줄 데이터 Map of (name: val)
    const [resulting, setResulting] = useState(false);  // invoke의 결과를 보여주는 Dialog를 보여주는가?
    const [invokeResult, setInvokeResult] = useState("");  // invoke의 결과 저장
    // url = '/bagel/obix/....'

    // https://kyounghwan01.github.io/blog/React/exhaustive-deps-warning/#_2-useeffect-%E1%84%82%E1%85%A2%E1%84%87%E1%85%AE%E1%84%8B%E1%85%A6-%E1%84%92%E1%85%A1%E1%86%B7%E1%84%89%E1%85%AE%E1%84%85%E1%85%B3%E1%86%AF-%E1%84%8C%E1%85%A5%E1%86%BC%E1%84%8B%E1%85%B4%E1%84%92%E1%85%A1%E1%86%AB-%E1%84%80%E1%85%A7%E1%86%BC%E1%84%8B%E1%85%AE
    // useEffect에서 getObix와 loadRangesAndParams를 호출하는데
    // 이 둘을 dependency로 넣어야 하는데... 그러면 또 다른 에러가 발생함.
    // 이렇게 useCallback을 쓰면 이 함수 정의 자체가 dependency가 변경될 때만 정의되기 때문에 효율적임. useMemo와 비슷.
    const getObix = useCallback((url) => {
        console.log(`getObix with ${url} / mmurl=${appState.mmurl}`);
        if (!appState.mmurl) return;
        setLoading(true);
        ObixUtil.readObix(appState.mmurl + url,
            (resp) => {
                setObix(resp.data);
                console.log(`resp=${resp.data}`);
                buildRows(resp.data);
                // ref만 추려서 위로 보고한다.
                let refs = [];
                for (let item of resp.data.c) {
                    if (item.o === "ref") {
                        refs.push(item.href);
                    }
                }
                props.updateRef(refs);
                setLoading(false);
            },
            (err) => {
                console.error(err);
                toast.current?.show({ severity: 'error', summary: 'Failed to load from bagel', detail: err });
                setLoading(false);
                // #79 에러나면 이전 URL로 돌아간다. prevUrl이 있고, 지금것과 다를때만 업데이트.
                // 이거 안하면 무한 반복에 빠질 수 있음.
                if (props.prevUrl && props.prevUrl !== url) {
                    props.updateUrl(props.prevUrl);
                }
            }
        );
    }, [appState.mmurl, props]);

    const loadRangesAndParams = useCallback(() => {
        /*
        {
            "range": "#pcs_state",
            "o": "enum",
            "href": "psxml:PcsState",
            "c": [
                {
                "o": "list",
                "href": "#pcs_state",
                "is": [
                    "obix:Range"
                ],
                "c": [
                    {
                        "o": "obj",
                        "displayName": "Stopped",
                        "name": "stopped"
                    },
                    {
                        "o": "obj",
                        "displayName": "Charging",
                        "name": "charging"
                    },
                    {
                        "o": "obj",
                        "displayName": "Discharging",
                        "name": "discharging"
                    },
                    {
                        "o": "obj",
                        "displayName": "Standby",
                        "name": "standby"
                    }
                ]
            }
        ]
        },
        */
        // http://localhost:8084/bagel/obix/def/psxml  에서 enum을 읽어옴
        // 함수 밖에 async를 두고 await 함수를 쓰면 동기 방식으로 사용 가능.
        ObixUtil.readObix(appState.mmurl + "/obix/def/psxml",
            (resp) => {
                console.log("Load response=", resp.data);
                let rangeMap = new Map();
                let paramMap = new Map();
                for (const item of resp.data.c) {
                    let range = [];
                    if (item.o === "enum") {  // enum -> list -> obj 의 형식임.
                        for (let row of item.c[0].c) {
                            range.push(row.name);
                        }
                        //item.c[0].c.map(gitem => {
                        //    range.push(gitem.name);
                        //});
                        rangeMap.set(item.href, range);
                    } else if (item.o === 'obj') {
                        paramMap.set(item.href, item.c);
                    }
                }
                setRangeMap(rangeMap);
                setParamMap(paramMap);
            },
            (err) => {
                console.error(err);
            }
        );
    }, [appState.mmurl]);

    const findRange = (href) => {
        return rangeMap.get(href);
    }

    const findParam = (href) => {
        //console.log(`href=${href}, type=${typeof href} param=${paramMap.get("psxml:CommPort")}`);
        // 왜 인지는 모르겠는데... o.val 로 집어넣은게 string이 아니라 object 타입이다.
        // 그래서 Map에서 검색이 안됨. 그래서 toString()으로 강제 형변환함.
        return paramMap.get(href?.toString());
    }

    const iconTemplate = (row) => {
        let style;
        let cname;
        if (row.tag === "ref") {
            if (row?.status !== "DISABLED") {
                cname = "pi pi-angle-double-right";
                style = { color: "limegreen", fontWeight: 900};
            } else {
                cname = "pi pi-angle-right";
                style = { color: "green", fontWeight: 100};
            }
        } else if (row.writable === true) {
            cname = "pi pi-pencil";
            style = { color: "mediumvioletred", fontWeight: 900};
        } else if (row.tag === "op") {
            cname = "pi pi-directions";
            style = { color: "LightSalmon", fontWeight: 900};
        } else {
            cname = "pi pi-info-circle";
            style = { color: "darkgray"};
        }

        return <i className={cname} style={style}/>;
    }

    const valTemplate = (row) => {
        let valstr = "";
        if (row.val !== null && row.val !== undefined) {
            valstr = row.val.toString();
        }
        if (row.unit && valstr) {
            valstr += " " + row.unit;
        }
        return <span>{valstr}</span>
    }

    const dispTemplate = (row) => {
        let dispstr = "";
        if (row.display) {
            dispstr = row.display;
        } else if (row.displayName) {
            dispstr = row.displayName;
        }
        return <span>{dispstr}</span>
    }

    // 가져온 obix 데이터로 DataTable을 만든다.
    const buildRows = (obix) => {
        let rows = [];
        for (let o of obix.c) {
            let row = {
                 name: o.name,
                 tag: o.o,
                 val: o.val,
                 display: o.display,
                 displayName: o.displayName,
                 writable: o.writable,
                 unit: o.u,
                 range: o.range,
                 status: o.status
            }
            if (o.o === "ref") {
                row.val = o.href;
            } else if (o.o === "err") {
                row.val = o.is;
            } else if (o.o === "op") {
                row.val = o.in;
            }
            rows.push(row);
        }
        setRowList(rows);
    };

    // Write Dialog를 만든다.
    const buildWriteWidget = useMemo(() => {
        //writeValue = row?.val;    // 초기값 세팅
        const row = selected
        if (row?.tag === "int") {
            return <InputNumber className="mb-2" value={writeValue} onValueChange={(e) => setWriteValue(e.value)} mode="decimal" maxFractionDigits={0} autoFocus/>
        } else if (row?.tag === "real") {
            return <InputNumber className="mb-2" value={writeValue} onValueChange={(e) => setWriteValue(e.value)} mode="decimal" minFractionDigits={1} maxFractionDigits={5} autoFocus/>
        } else if (row?.tag === "enum") { //TODO: 실제 enum 읽어와야 함.
            // 왜 두가지 타입이 있는겨???? range에 있는것도 is에 있는 것도 있음.
            // Obix 문서에는 range에 쓰라고 되어 있음.
            //<enum name="opMode" writable="true" val="charging" range="psxml:PcsStateEx"/>
            //<enum name="transport" is="psxml:Transport psxml:Save" writable="true" val="tcp"/>
            let range = findRange(selected.range)
            //const options = [ 'Choice1', 'Choice2', 'Choice3', 'Choice4'];
            // appendTo 가 중요한데... 이게 Dropdown 눌렀을때 나오는 선택창을 어디 아래로 둘거인지이다.
            // 이걸 document.body로 하지 않으면 Dialog 아래로 들어가 Dialog를 넘어서지 못함.
            return <Dropdown className="mb-2" value={writeValue} options={range} onChange={(e) => setWriteValue(e.value)}
                placeholder="Select Value" appendTo={document.body}/>  //TODO: 선택으로 바꾸야 함.
        } else if (row?.tag === "bool") {
            const options = [
                { name: "True", value: true },
                { name: "False", value: false }
            ];
            return <SelectButton className="mb-2" value={writeValue} options={options} onChange={(e) => setWriteValue(e.value)} optionLabel="name"/>
        } else {  // 나머지는 str, abstime, reltime 등
            return <InputText className="mb-2" value={writeValue} onChange={(e) => setWriteValue(e.target.value)} autoFocus/>
        }
    }, [selected, rangeMap, writeValue]);

    // DataTable의 Row를 클릭했을 때...
    const onRowClick = (e) => {
        setSelected(e.data);
        setWriteValue(e.data?.val);
        if (e.data.tag === "ref") {
            console.log(`Ref to ${e.data.name}`);
            props.updateUrl(props.url + "/" + e.data.val);
        //} else if (e.data.writable === true && authContext.isAdmin) {
        } else if (e.data.writable === true) { //TODO: 일단 권한은 다 푼다
            console.log("Clicked writable");
            setWriting(true);
        //} else if (e.data.tag === "op" && authContext.isAdmin) {
        } else if (e.data.tag === "op") {  //TODO: 일단 권한은 다 푼다
            // https://sung.codes/blog/2018/12/07/setting-react-hooks-states-in-a-sync-like-manner/  참고
            // https://dev.to/bytebodger/synchronous-state-with-react-hooks-1k4f   useTrait 커스텀 후크 만들어서???
            // https://ysfaran.github.io/blog/post/0002-use-state-with-promise/  useStateWithPromise
            // https://www.npmjs.com/package/use-state-promise    아예 만들어 놨음.
            //const widgets = buildInvokeWidgets(selected);
            //setInvokeWidgetsPromise(widgets).then((state) => setInvokeValues(true));
            setInvokeValues(new Map());  // 기존의 form 값을 모두 클리어
            setInvoking(true);  // widget 변경이 되기 전에 보여줘서... 문제네...
        }
    };

    const writeObix = () => {
        let writeUrl = appState.mmurl + props.url;
        let body = {
            o: 'obj',
            c: [
                { o: selected.tag, name: selected.name, val: writeValue }
            ]
        }
        ObixUtil.writeObix(writeUrl, body,
            (resp) => {
                toast.current.show({ severity: 'success', summary: 'Property written successfully', detail: '' });
                setWriting(false);
                // Write후 응답을 가지고 새로 그림
                setObix(resp.data);
                buildRows(resp.data);
            },
            (err) => {
                toast.current.show({ severity: 'error', summary: 'Failed to write obix', detail: err });
                setWriting(false);
            }
        );
    }

    const updateInvokeValue = (name, val) => {
        // Map이 state인 경우에는 immutable 원칙에 의해서, Map을 통째로 교체해주어야 함.
        console.log(`updateInvokeValue: name=${name} val=${val} type=${typeof val}`);
        setInvokeValues((prev) => new Map(prev).set(name, val));
    }

    const getInvokeValue = (name, defval) => {
        // 이렇게 깔끔하게, default 값 처리할 수 있네???????
        console.log(`getInvokeValue: name=${name}, def=${defval}`);
        if (invokeValues.get(name) === undefined) {
            //updateInvokeValue(name, defval);
            return defval;
        } else {
            return invokeValues.get(name);
        }
    }

    // 이 함수는 엄청 복잡하기 때문에 계속 불리면 안됨. useMemo를 써서 최적화함.
    const buildInvokeWidgets = useMemo(() => {
        console.log("invokeWidgets entered");
        const row = selected;
        if (!row || !row.val) return;

        let inParam = findParam(row.val);  // op는 buildRows 함수에서 in을 val로 넣었음.
        const inFields = [];
        let rowval = row.val.toString();  // row.val 이 스트링이 아니고 object이네???
        //console.log(`row.val=${rowval}, type=${typeof rowval}, inParam=${inParam}`);
        if (!inParam && (rowval.startsWith("http://") || rowval.startsWith("https://"))) {
            // port.addDevice 처럼 원격에 있는 param인 경우임.
            ObixUtil.readObix(rowval,
                (resp) => {
                    inParam = resp.data.c;
                    setParamMap((prev) => new Map(prev).set(rowval, inParam));  // paramMap에 업데이트시켜 줌.
                },
                (err) => {
                    console.error(err);
                }
            );
            /*
            axios.get(rowval).then((resp) => {
                inParam = resp.data.c;
                setParamMap((prev) => new Map(prev).set(rowval, inParam));  // paramMap에 업데이트시켜 줌.
            });
            */
        }
        if (inParam) {  // param이 Map에 있으면
            // 디톨트 값을 먼저 채워준다. inParam의 val에 값이 있으면 default 값임.
            // 아래 코드가 의도대로 먹지 않음.
            /*
            for (const item of inParam) {
                if (item.val !== null && item.val !== undefined) {
                    updateInvokeValue(item.name, item.val);
                }
            }
            */
            for (const item of inParam) {
                if (item.o === "int") {
                    inFields.push(
                        <div key={item.name} className="grid">
                            <label htmlFor={item.name} className={classnames("col-3 col-align-center", {"fw-500": !item?._null})}>{item.name}</label>
                            <InputNumber id={item.name} className="mb-0" value={getInvokeValue(item.name, item.val)}
                                onValueChange={(e) => updateInvokeValue(item.name, e.value)} mode="decimal" maxFractionDigits={0} useGrouping={false} />
                            <small className="col-12 mb-2 pt-1" style={{textAlign: "right", fontStyle:"italic"}}>{item.displayName}</small>
                        </div>
                    );
                } else if (item.o === "real") {
                    inFields.push(
                        <div key={item.name} className="grid">
                            <label htmlFor={item.name} className={classnames("col-3 col-align-center", {"fw-500": !item?._null})}>{item.name}</label>
                            <InputNumber className="mb-0" value={getInvokeValue(item.name, item.val)}
                                onValueChange={(e) => updateInvokeValue(item.name, e.value)} mode="decimal" minFractionDigits={1} maxFractionDigits={5} useGrouping={false} />
                            <small className="col-12 mb-2 pt-1" style={{textAlign: "right", fontStyle:"italic"}}>{item.displayName}</small>
                        </div>
                    );
                } else if (item.o === "enum") { //TODO: 실제 enum 읽어와야 함.
                    // 왜 두가지 타입이 있는겨???? range에 있는것도 is에 있는 것도 있음.
                    // Obix 문서에는 range에 쓰라고 되어 있음.
                    //<enum name="opMode" writable="true" val="charging" range="psxml:PcsStateEx"/>
                    //<enum name="transport" is="psxml:Transport psxml:Save" writable="true" val="tcp"/>
                    let range = findRange(item.range)
                    let rangeval = item.range.toString();  // row.val 이 스트링이 아니고 object이네???
                    if (!range && (rangeval.startsWith("http://") || rangeval.startsWith("https://"))) {
                        // port.addDevice 처럼 원격에 있는 range인 경우임.
                        ObixUtil.readObix(rangeval,
                            (resp) => {
                                range = [];
                                for (const o of resp.data.c) {
                                    range.push(o.name);
                                }
                                setRangeMap((prev) => new Map(prev).set(rangeval, range));  // paramMap에 업데이트시켜 줌.
                            },
                            (err) => {
                                console.error(err);
                            }
                        );
                        /*
                        axios.get(rangeval).then((resp) => {
                            range = [];
                            for (const o of resp.data.c) {
                                range.push(o.name);
                            }
                            setRangeMap((prev) => new Map(prev).set(rangeval, range));  // paramMap에 업데이트시켜 줌.
                        });
                        */
                    }
                    //const options = [ 'Choice1', 'Choice2', 'Choice3', 'Choice4'];
                    // appendTo 가 중요한데... 이게 Dropdown 눌렀을때 나오는 선택창을 어디 아래로 둘거인지이다.
                    // 이걸 document.body로 하지 않으면 Dialog 아래로 들어가 Dialog를 넘어서지 못함.
                    inFields.push(
                        <div key={item.name} className="grid">
                            <label htmlFor={item.name} className={classnames("col-3 col-align-center", {"fw-500": !item?._null})}>{item.name}</label>
                            <Dropdown id={item.name} className="col-9 m-0 p-0" value={getInvokeValue(item.name, item.val)} options={range}
                                onChange={(e) => updateInvokeValue(item.name, e.value)} placeholder="Select Value" appendTo={document.body}/>
                            <small className="col-12 mb-2 pt-1" style={{textAlign: "right", fontStyle:"italic"}}>{item.displayName}</small>
                        </div>
                    );  //TODO: 선택으로 바꾸야 함.
                } else if (item.o === "bool") {
                    const options = [
                        { name: "True", value: true },
                        { name: "False", value: false }
                    ];
                    inFields.push(
                        <div key={item.name} className="grid align-center mb-3">
                            <label htmlFor={item.name} className={classnames("col-3 col-align-center", {"fw-500": !item?._null})}>{item.name}</label>
                            <InputSwitch id={item.name} className="col-1" checked={getInvokeValue(item.name, item.val)} onChange={(e) => updateInvokeValue(item.name, e.value)} />

                            {/*<SelectButton id={item.name} className="p-col-4 p-mb-2 p-px-0" value={getInvokeValue(item.name, item.val)} options={options}
                                onChange={(e) => {console.log(e); updateInvokeValue(item.name, e.value)}} optionLabel="name"/>*/}
                            <small className="col-8 my-0 py-0" style={{textAlign: "right", fontStyle:"italic"}}>{item.displayName}</small>
                        </div>
                    );
                } else {  // 나머지는 str, abstime, reltime 등
                    inFields.push(
                        <div key={item.name} className="grid">
                            <label htmlFor={item.name} className={classnames("col-3 col-align-center", {"fw-500": !item?._null})}>{item.name}</label>
                            <InputText id={item.name} className="mb-0 col-9" value={getInvokeValue(item.name, item.val)}
                                onChange={(e) => updateInvokeValue(item.name, e.target.value)} />
                            <small className="col-12 mb-2 pt-1" style={{textAlign: "right", fontStyle:"italic"}}>{item.displayName}</small>
                        </div>
                    );
                }
            }
            return inFields;
        }
    }, [selected, paramMap, rangeMap, invokeValues]);

    const invokeObix = () => {
        let invokeUrl = appState.mmurl + props.url + "/" + selected?.name;
        let body = {
            o: 'obj',
            c: []
        }

        const inParam = findParam(selected?.val);  // op는 buildRows 함수에서 in을 val로 넣었음.
        if (inParam) {  // 입력인자가 obix:Nil 같이 아무것도 없는 것도 있음.
            for (const item of inParam) {
                let val = item.val;  // 일단 디폴트를 가져옴.
                if (invokeValues.get(item.name) !== undefined) {
                    val = invokeValues.get(item.name);
                }
                body.c.push({
                    o: item.o,
                    name: item.name,
                    val: val
                });
                console.log(`k=${item.name}, v=${val}, type=${item.o}`);
            }
        }
        ObixUtil.invokeObix(invokeUrl, body,
            (resp) => {
                // 결과 보여주는 창 띄우기
                setInvokeResult(resp.data);
                setResulting(true);  // invoke 다이얼로그는 지우고, 결과 Dialog 띄우기
                setInvoking(false);
                getObix(props.url);   // invoke 후 새로 값 리로딩
                //toast.current.show({ severity: 'success', summary: 'Invoked Op successfully', detail: selected?.name });
                // setWriting(false);
                // Write후 응답을 가지고 새로 그림
                //setObix(resp.data);
                //buildRows(resp.data);
                //TODO: 현재 URL reload, setInvoking(false) 처리, 응답 dialog 띄우기.
            },
            (err) => {
                toast.current.show({ severity: 'error', summary: 'Failed to invoke obix', detail: err, life: 10000 });
            }
        );
    }

    const writeDialogFooter =
        <div>
            <Button label="Cancel" icon="pi pi-times" onClick={() => setWriting(false)} className="p-button-text" />
            <Button label="Apply" icon="pi pi-check" onClick={() => writeObix()} />
        </div>;

    const invokeDialogFooter =
        <div>
            <Button label="Cancel" icon="pi pi-times" onClick={() => setInvoking(false)} className="p-button-text" />
            <Button label="Invoke" icon="pi pi-check" onClick={() => invokeObix()} />
        </div>;

    const resultDialogFooter =
        <div>
            <Button label="OK" icon="pi pi-check" onClick={(e) => setResulting(false)} />
        </div>;


    useEffect(() => {
        getObix(props.url);
        //loadRangesAndParams();  // 필요없이 많이 불림
    }, [props.url, appState.mmurl, props.ts])

    useEffect(() => {
        loadRangesAndParams();
    }, [appState.mmurl]);

    const JsonStyle = {
        propertyStyle: { color: 'red' },
        stringStyle: { color: 'green' },
        numberStyle: { color: 'darkorange' }
    }

    return (
        <div>
            <Toast ref={toast}></Toast>
            <DataTable value={rowList} resizableColumns columnResizeMode="fit" showGridlines selectionMode="single" onRowClick={onRowClick}
                responsiveLayout="scroll" className="">
                <Column field="icon" header="" body={iconTemplate} style={{width:'32px'}}></Column>
                <Column field="name" header="Name"></Column>
                <Column field="tag" header="Tag" style={{width:'80px'}}></Column>
                <Column field="val" header="Value" body={valTemplate}></Column>
                <Column field="disp" header="Display(Name)" body={dispTemplate}></Column>
            </DataTable>
            {/* Loading Modal Dialog */}
            <Dialog visible={loading} modal={true} resizable={false} draggable={false} closable={false} showHeader={false}
                blockScroll={true} style={{width:200, height:45}} contentStyle={{textAlign: "center"}} onHide={(e) => setLoading(false)}>
                <ProgressBar className="mt-4" mode="indeterminate" />
                {/*<i className="pi pi-spin pi-spinner" style={{'fontSize': '2em'}}></i>*/}
            </Dialog>
            {/* Write Property Dialog */}
            {/* enum을 위한 Dropdown이 자꾸 다이얼로그 끝을 넘어가서 보이지 않는 버그가 있음. 이를 막기 위해 height를 좀 주었음.
               ==> Dropdown의 appendTo 속성을 이용하여 해결하였음.
               https://github.com/primefaces/primereact/issues/316
             */}
            <Dialog header="Write Property" visible={writing} onHide={(e) => setWriting(false)} footer={writeDialogFooter}
                contentStyle={{width:500}} modal={true}>
                <div className="flex flex-column">
                    <label className="mb-3">Property: {selected?.name} (type={selected?.tag})</label>
                    {buildWriteWidget}
                    <small>{selected?.displayName}</small>
                </div>
            </Dialog>
            <Dialog header="Invoke Op" visible={invoking} onHide={(e) => setInvoking(false)} footer={invokeDialogFooter}
                contentStyle={{width:500}} modal={true}>
                <div className="mb-4">{props.url + "/" + selected?.name}</div>
                {/*<small>&nbsp;</small>*/}
                <div>
                    {buildInvokeWidgets}
                    {/*{invokeWidgets}*/}
                </div>
            </Dialog>
            <Dialog header="Invoke Result" visible={resulting} onHide={(e) => setResulting(false)} footer={resultDialogFooter}
                contentStyle={{width:500}} modal={true}>
                <div>
                    {/*<JsonFormatter json={JSON.stringify(invokeResult)} tabWith={4} JsonStyle={JsonStyle} />*/}
                    <JSONPretty data={JSON.stringify(invokeResult)}/>
                </div>
            </Dialog>
        </div>
    );
}

function ObixJsonView(props) {
    //const appContext = useContext(AppContext);
    const appState = useRecoilValue(AppStateAtom);
    const [obix, setObix] = useState("");

    useEffect(() => {
        // useEffect에서만 쓰는 함수는 useEffect 안에 정의하는 것이, 매번 렌더링할때마다 호출되지 않아서 좋다.
        // es-lint에서도 그렇게 안내한다.
        // https://github.com/facebook/react/issues/14920
        const getObix = (url) => {
            console.log(`getObix with ${url}`);
            ObixUtil.readObix(appState.mmurl + url,
                (resp) => {
                    setObix(resp.data);
                    console.log(`resp=${resp.data}`);
                    // ref만 추려서 위로 보고한다.
                    let refs = [];
                    for (let item of resp.data.c) {
                        if (item.o === "ref") {
                            refs.push(item.href);
                        }
                    }
                    props.updateRef(refs);
                },
                (err) => {
                    console.error(err);
                }
            );
            /*
            axios.get(energleContext.sgurl + url)
                .then((resp) => {
                    setObix(resp.data);
                    console.log(`resp=${resp.data}`);
                    // ref만 추려서 위로 보고한다.
                    let refs = [];
                    for (let item of resp.data.c) {
                        if (item.o === "ref") {
                            refs.push(item.href);
                        }
                    }
                    props.updateRef(refs);
                })
                .catch((err) => {
                    console.error(err);
                });
            */
        };

        getObix(props.url);
    }, [props.url, appState.mmurl, props.ts]);

    const JsonStyle = {
        propertyStyle: { color: 'red' },
        stringStyle: { color: 'green' },
        numberStyle: { color: 'darkorange' }
    }

    return (
        <div>
            {/*<JsonFormatter json={JSON.stringify(obix)} tabWith={4} JsonStyle={JsonStyle} />*/}
            <JSONPretty data={JSON.stringify(obix)}/>
        </div>
    );
}

function ObixXmlView(props) {
    //const appContext = useContext(AppContext);
    const appState = useRecoilValue(AppStateAtom);
    const [obix, setObix] = useState("");

    useEffect(() => {
        const getObix = (url) => {
            console.log(`getObix with ${url}`);
            ObixUtil.readObixXml(appState.mmurl + url,
                (resp) => {
                    setObix(resp.data);
                    console.log(`resp=${resp.data}`);
                },
                (err) => {
                    console.error(err);
                }
            );
        };

        getObix(props.url);
    }, [props.url, appState.mmurl, props.ts]);

    return (
        <div>
            <XMLViewer xml={obix} />
        </div>
    );
}
